<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>회원관리</h1></div>
    <common-area-search
        :row="2"
        :colspan="2"
        @search="commonOnChangeEvent"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R2_C1>
        <label>통합검색</label>
        <ul>
          <input
              ref="compoundTextRef"
              class="k-textbox w100"
              placeholder="회사명, 회원명으로 검색"
              @keyup.enter="commonOnChangeEvent"
          />
        </ul>
      </template>
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              :params="{ searchCmpyGbn : '' }"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
              @change="value=>{
                vm.propsCmpyCd = value
                vm.$refs.authCdRef.widget().value('')
                vm.commonOnChangeEvent()
              }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>권한구분 </label>
        <ul>
          <common-multi-drop-down
              ref="authCdRef"
              @change="commonOnChangeEvent"
              :disabled="!propsCmpyCd"
              :params="{ searchCmpyCd : propsCmpyCd }"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/auth`"
              :data-value-field="'authCd'"
              :data-text-field="'authNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fl">
          <button @click="$router.push({ name: 'UserManageNew' })" class="mid_btn orange mr5 ">회원등록</button>
         </div>
        <div class="c_t_button_wrap fr">
          <button class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
        </div>
      </div>

      <div class="content_list">
        <!-- Kendo 영역 -->
        <kendo-grid ref="gridRef"
                    style="cursor: pointer"
                    :data-source="computedUserGridRows"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="gridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
                    @change="onChangeGrid"
        />
        <!-- Kendo 영역 끝 -->
      </div>
    </section>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'

export default {
  name: 'UserManageList',
  computed: {
    computedUserGridRows: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/manage/user`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
                ...filters
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.gridRef.dataSource.page() - 1)
                * vm.$refs.gridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              // row 번호 셋팅
              row.rowNumber = response.total - rowNumber++
              row.searchName = vm.$refs.compoundTextRef.value
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name === 'UserManageDetail'){
        vm.$refs.gridRef.kendoWidget()?.dataSource.read()
      }
    })
  },
  mounted () {},
  methods: {
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 통합검색, 회사코드, 권한,
       * */
      refs.compoundTextRef.value = ''
      refs.cmpyCdRef.widget().value('')
      refs.authCdRef.widget().value('')
      this.propsCmpyCd = ''
      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      // 통합검색
      const compoundText = refs.compoundTextRef.value
      if (!!compoundText) {searchFilterOptions.searchCompoundText = compoundText}

      //회사코드
      const cmpyCd = refs.cmpyCdRef.widget().value()
      if (!!cmpyCd) {searchFilterOptions.searchCmpyCd = cmpyCd}

      //권한
      const authCd = refs.authCdRef.widget().value()
      if (!!authCd) {searchFilterOptions.searchAuthCdList = [authCd]}

      return searchFilterOptions ?? undefined
    },
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.$router.push(
            { name: 'UserManageDetail',
              params: { unqUserId : rowData.unqUserId }
            })
      }
    },
    commonOnChangeEvent: function () {
      const gridRef = this.$refs.gridRef
      gridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/user`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let userListGridColumns = vm.gridColumns.reduce((acc, cur) => {
              if(cur.field !== 'rowNumber'){ acc.push({ ...cur, width : '120px' }) }
              return acc;
            }, []);

            saveExcel({
              data: resultData,
              fileName: '회원목록_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: userListGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const vm = this
    return {
      vm: vm,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      isWindowOpen : false,
      propsCmpyCd : '',
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '15%',
          headerAttributes: headerAttributes,
          template: function (dataItem) {
            return EtnersEfsUtil.emphasisGridSearchText(dataItem.searchName, dataItem.cmpyNm)
          }
        },
        {
          field: 'locaNm',
          title: '기본로케이션',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'authNm',
          title: '권한',
          width: '7%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'userId',
          title: 'ID',
          width: '7%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'userNm',
          title: '성명',
          width: '7%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: function (dataItem) {
            return EtnersEfsUtil.emphasisGridSearchText(dataItem.searchName, dataItem.userNm)
          }
        },
        {
          field: 'spTelNo',
          title: '휴대전화번호',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes : centerAttributes
        },
        {
          field: 'email',
          title: '메일주소',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes : centerAttributes
        },
        {
          field: 'frstRgstDt',
          title: '등록일시',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'lastMdfyDt',
          title: '최종수정일시',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'activeYn',
          title: '사용여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: function (dataItem) {
            return dataItem.activeYn === 'Y' ? '활성화' : '비활성화'
          }
        }
      ],
    }
  },
}
</script>
